'use client';
import { Button, Icon, Link } from '@theme/components';
import { WidgetData } from './type';

import { useLocalization } from '@akinon/next/hooks';

const NotFoundLinks = ({ not_found_links }: WidgetData) => {
  const { t } = useLocalization();

  if (!not_found_links || Object.keys(not_found_links).length === 0)
    return null;

  return (
    <section className="container flex flex-col items-center justify-center text-center text-primary">
      <div className="mb-6 mt-2 flex h-20 w-20 items-center justify-center md:mt-10">
        <Icon
          name="close"
          size={32}
          className="flex h-[37.3px] w-[37.3px] items-center justify-center rounded-full bg-error text-white md:h-[46.7px] md:w-[46.7px]"
        />
      </div>
      <h1 className="mb-3 text-2xl leading-8 tracking-[-0.4px] md:text-[40px] md:leading-[52px]">
        {t('common.page_404.title')}
      </h1>

      <p className="mb-8 text-lg md:text-2xl">
        {t('common.page_404.description1')}
      </p>
      <p className="text-lg md:text-2xl">
        {t('common.page_404.description2_first')}
      </p>
      <p className="text-lg md:text-2xl">
        {t('common.page_404.description2_second')}
      </p>
      <Link
        prefetch={false}
        href={'/'}
        className="mb-[72px] mt-8 w-full bg-primary py-3 text-center text-base text-white md:mt-10 md:w-[300px]"
      >
        {t('common.page_404.homepage')}
      </Link>
      <div className="mx-auto mb-[31px] flex max-w-[820px] flex-wrap justify-center gap-2 md:mb-16 md:gap-6">
        {not_found_links?.map((item, index) => {
          return (
            <Button key={index} appearance="outlined" className="mb-2 p-0">
              <Link
                className="px-3 py-2 md:px-4 md:py-3"
                prefetch={false}
                href={item.value.link}
              >
                {item.value.text}
              </Link>
            </Button>
          );
        })}
      </div>
    </section>
  );
};

export default NotFoundLinks;
